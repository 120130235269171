<template>
    <div class="page-domain-technical-contact" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row" v-if="!on_sidebar">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="back_to">Back to domain.name.com</router-link>

                    <div class="header-info">
                        <h1 class="heading">Technical Contact</h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <div class="board">
                        <h3 class="heading">Edit Details</h3>

                        <app-loader v-if="processing"></app-loader>

                        <app-error v-model="errors.save"></app-error>

                        <app-input v-model="settings.FirstName"                label="First name"   :error="errors.FirstName"   @change="errors.FirstName = null"   :disabled="disabled" />
                        <app-input v-model="settings.LastName"                 label="Last name"    :error="errors.LastName"    @change="errors.LastName = null"    :disabled="disabled" />
                        <app-input v-model="settings.Email"       type="email" label="E-mail"       :error="errors.Email"       @change="errors.Email = null"       :disabled="disabled" />
                        <app-input v-model="settings.Address"                  label="Address"      :error="errors.Address"     @change="errors.Address = null"     :disabled="disabled" />

                        <div class="input-line-double">
                            <app-input v-model="settings.City"                     label="City"         :error="errors.City"        @change="errors.City = null"        :disabled="disabled" />
                            <app-input v-model="settings.PostalCode"               label="Postal Code"  :error="errors.PostalCode"  @change="errors.PostalCode = null"  :disabled="disabled" />
                        </div>

                        <app-input v-model="settings.Country"                  label="Country"      :error="errors.Country"     @change="errors.Country = null"     :disabled="disabled" />
                        <app-input v-model="settings.PhoneNumber"              label="Phone number" :error="errors.PhoneNumber" @change="errors.PhoneNumber = null" :disabled="disabled" />

                        <button class="btn btn-small btn-primary" @click="save" :disabled="disabled || !is_changed" v-if="!on_sidebar">Save</button>
                    </div>

                    <button class="btn btn-full-width btn-primary" @click="save" :disabled="disabled || !is_changed" v-if="on_sidebar">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'

import errMessage from '@/helpers/errMessage'
import DomainValidator from '@/validators/domain-validator'

const TECHNICAL_CONTACT_INFO = {
    FirstName:   'Joshua',
    LastName:    'Hanson',
    Email:       'joshua-hanson@iqhive.com',
    Address:     '11-21 Fitzpatrick Street',
    City:        'Wellington',
    PostalCode:  '6037',
    Country:     'New Zealand',
    PhoneNumber: '+64219191191',
}

export default {
    components: {
        appLoader,
        appError,

        appInput,
    },

    data() {
        return {
            settings: {
                FirstName:   null,
                LastName:    null,
                Email:       null,
                Address:     null,
                City:        null,
                PostalCode:  null,
                Country:     null,
                PhoneNumber: null,
            },
            original: {},

            loading:    false,
            processing: false,

            errors: {},

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadTechnicalContactInfo()
        },

        loadTechnicalContactInfo() {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                this.settings = { ...TECHNICAL_CONTACT_INFO }
                this.original = { ...TECHNICAL_CONTACT_INFO }

                this.loading = false
            }, delay_in_ms)
        },

        save() {
            if (this.is_changed) {
                if (this.validation()) {
                    const delay_in_ms = 1000

                    this.processing = true

                    setTimeout(() => {
                        this.settings = { ...TECHNICAL_CONTACT_INFO }
                        this.original = { ...TECHNICAL_CONTACT_INFO }

                        this.$emit('saved')

                        this.processing = false
                    }, delay_in_ms)
                } else {
                    if (this.on_sidebar) {
                        this.$parent.$refs.body.scrollTo(0, 0)
                    } else {
                        window.scrollTo(0, 0)
                    }
                }
            }
        },

        validation() {
            let is_valid = true
            this.errors = {}

            const values = { ...this.settings }

            for (const field in values) {
                if (DomainValidator.isRuleExists(field)) {
                    const error = DomainValidator.isInvalid(field, values[field])

                    if (error) {
                        this.$set(this.errors, field, error)
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        exit(route) {
            if (this.on_sidebar) {
                this.$emit('close-sidebar')
            } else if (route) {
                this.$router.push({ ...route, replace: true })
            }
        },

        reset() {
            this.settings = { ...this.original }

            this.errors = {}
        },
    },

    computed: {
        domain_uuid() {
            return this.$route.params.uuid
        },

        is_changed() {
            let is_changed = false

            for (const key in this.settings) {
                if (this.settings[key] !== this.original[key]) {
                    is_changed = true
                    break
                }
            }

            return is_changed
        },

        back_to() {
            return { name: 'domain', params: { uuid: this.domain_uuid } }
        },

        disabled() {
            return Boolean(this.loading) || Boolean(this.processing)
        },
    },
}
</script>

<style lang="scss">
.page-domain-technical-contact {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .col-12 {
        margin-top: 30px;

        &:first-child { margin: 0 };
    }

    .board {
        position: relative;

        padding: 24px;

        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);

        .app-error {
            margin-top: 16px;
        }

        .app-input {
            margin: 24px 0 0;
        }

        .input-line-double {
            display: flex;
            justify-content: space-between;

            .app-input {
                &:first-child {
                    width: calc(60% - 15px);
                }

                &:last-child {
                    width: calc(40% - 15px);
                }
            }
        }

        & > .btn,
        & + .btn {
            margin-top: 32px;
        }
    }

    &.on-sidebar {
        .app-loader {
            top: -4px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-domain-technical-contact {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }

        .board {
            padding: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-domain-technical-contact {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .board {
            padding: 16px 8px 8px;

            .input-line-double {
                flex-direction: column;

                .app-input {
                    &:first-child {
                        width: 100%;
                    }

                    &:last-child {
                        width: 100%;
                    }
                }
            }

            & > .btn {
                max-width: 100%;
            }

            & + .btn {
                margin-top: 15px;
            }
        }
    }
}
</style>